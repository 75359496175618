/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
require('../css/app.scss');

let qq = require('fine-uploader/lib/all');
window.qq = qq;
const $ = require('jquery');
window.$ = $;
window.jQuery = $;
window.moment = require('moment');
window.autosize = require('autosize');
require('bootstrap');
require('bootstrap4-toggle');
require('bootstrap-datepicker');
require('datatables.net');
require('datatables.net-bs4');
require('tempusdominus-bootstrap-4');
require('select2');

jQuery(document).ready(function() {
    // console.log('hello');
});
